.search-box{
    position: relative;
    width: 100%;
    margin-right: 16px;
    height:38px;
    button{
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        position: absolute;
        right: 0px;
        top: 0;
        background: #FF9900;
        color: #fff;
        width: 35px;
        height: 100%;
        border-color: #FF9900;
        box-shadow: none !important;
        border: 0px;
    }   
    input{
        width: calc( 100% - 18px);
        height: 100%;
        border-color: color(gray-100);
        border-top-left-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;
        outline: 0px !important;
    }
}
.category-search{
    position: relative;
    width: 100%;
    height:40px;
    i{
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
    }   
    input{
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border:none;
        background:#F3F3F3;
        padding: 0 15px;
    }
}
.branchsearch{
    width: 80%;
    height: 45px;
}
.branchsearch-box{
    position: relative;
    width: 100%;
    margin-right: 16px;
    button{
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        position: absolute;
        right: 0px;
        top: 0;
        background: #0A4A77;
        color: #fff;
        width: 35px;
        height: 100%;
        border-color: #0A4A77;
        box-shadow: none !important;
        border: 0px;
    }
    input{
        width: calc( 100% - 18px);
        border-color: color(gray-100);
        border-bottom-right-radius: 0.25rem !important;
        border-top-right-radius: 0.25rem !important;
    }
}
.search-block{
    position: relative;
    width: 100%;
    margin-right: 16px;
    input{
        padding-left: 30px !important;
        border: none;
        background: #fff !important;
        border-bottom:1px solid #c3c3c3;
        max-width: 300px;
        border-radius: 0px;
    }
    i{
        position: absolute;
        top:50%;
        left:5px;
        transform: translateY(-50%);
    }
}
.category-button{
    background-color: color(gray-200) !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.location-search{
    .form{
        min-width: 55%;
        max-width: 250px;
    }
    input{
        border: 1px solid color(gray-500);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-left: 25px;
        max-width: 250px;
        font-size: 13px; 
    }
    button{
        font-size: 13px;
    }
    i{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        font-size: 13px;
    }
}
.form-switch{
    input[type='checkbox']{
        &::before{
            content: inherit !important;
        }
        &:after{
            content: inherit !important;
        }
    }
}
input[type='checkbox']{
    position: relative;
    &::before{
        content:'';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 15px;
        height: 15px;
        border-radius: 3px;
        border: 1.5px solid #E6E6E6;
        background: color(white);
    }
    &::after{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) rotate(309deg);
        width: 8px;
        height: 4px;
        border-left: 2px solid color(white);
        border-bottom: 2px solid color(white);
        z-index: 1;
    }
}
input:checked {
    &::before{
        background:#FF9900;
        border: none;
    }
}

.coupon-code{
    position: relative;
    @include d-flex-wrap;
    .form-label{
        width: 100%;
    }
    button{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    } 
    input{ 
        border-color:color(dark);
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    } 
}
.qty-drop{
    max-width: 60px;
    height: 30px;
    font-size: 10px;
    display: inline-block;
    margin-right: 10px;
}
.login{
    & input, & button{
        height: 48px;
    }
}

.cursor{
    cursor: pointer;
}